@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactsIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.footerContent>div,
.footerContent ul {
  text-align: center;
}

.social-icon,
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.footerIcon {
  font-size: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  width: 50px;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

.social-icon__link:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

@media (min-width: 600px) {
  .footerContent {
    flex-direction: row;
    justify-content: space-around;
  }
}