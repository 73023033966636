  #formInput label {
    font-weight: bold;
  }
  
  #formInput input {
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    padding: 0.5rem;
  }
  
