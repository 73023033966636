body {
  margin: 0px;
  padding: 0px;
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1px;
}

input {
  outline: none;
  border: none;
}

.logo img {
  width: 15rem;
  height: 14rem;
}

#reservation {
  padding-top: 20rem;
  width: 100%;
  height: 850px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./images/bgimage.jpg");
}

.dateBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
}

.priceBox {
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
  text-align: center;
  width: 150px;
}

.noSpotsError {
  background-color: rgba(255, 0, 0, 0.733);
  color: #ffffff;
  text-align: center;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 5px;
  width: 70%;
  max-width: 400px;
  font-size: 1rem;
}

.dateBox {
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 200px;
}

.dateBoxTitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.dateRangeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedDates {
  display: flex;
}

.dateInput {
  position: absolute;
}

.dateInput {
  left: 50%;
  transform: translateX(-50%);
}

.headerDateInputText,
.checkButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 20px;
}

.headerDateInputText:hover,
.checkButton:hover {
  background-color: #0056b3;
  color: white;
}


#reservationBox {
  text-align: center;
  background-color: rgba(209, 209, 209, 0.692);
  width: 100%;
  margin: auto;
  padding: 3rem;
}

.pricesBoxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.headerDateInputText {
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  border-radius: 20px;
}

.headerDateInput {
  width: fit-content;
  justify-content: center;
}

.dateRangeBox {
  width: max-content;
}

.date {
  position: absolute;
  top: 0
}

nav {
  height: 65px;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0rem 30px;
  z-index: 1;
  background-color: hsla(0, 0%, 100%, 0.4)
}

nav ul {
  display: flex;
}

nav ul li a {
  width: max-content;
  font-family: calibri;
  height: 40px;
  max-width: 20;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

nav ul li a:hover {
  background-color: #0056b3;
  color: white;
  transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
  display: none;
}

@media(max-width:1190px) {
  #main {
    background-size: 1150px !important;
  }
}

@media(max-width:970px) {
  #main {
    background-size: 970px !important;
  }
}

@media(max-width:768px) {
  #main {
    background-size: 800px !important;
  }
  .menu-icon {
    display: block;
  }

  nav {
    justify-content: space-between;
    height: 65px;
    padding: 0px 30px;
  }

  .menu {
    display: none;
    position: absolute;
    top: 65px;
    right: 0px;
    padding: 0px;
    margin: 0px;
  }

  .menu li {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  nav .menu li a {
    width: 100vw;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px rgb(64, 140, 226) solid;
  }

  nav .menu li:first-child a, nav .menu li:nth-child(2) a{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  nav .menu li:last-child a, nav .menu li:nth-child(2) a{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }

  nav .menu-icon .nav-icon {
    background-color: #007bff;
    position: absolute;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  
  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: #007bff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  
  nav .menu-icon .nav-icon:before {
    top: 5px;
  }
  
  nav .menu-icon .nav-icon:after {
    top: -5px;
  }
  
  nav .menu-icon .nav-icon.open {
    background: transparent;
  }
  
  nav .menu-icon .nav-icon.open:before {
    transform: rotate(-45deg);
    top: 0;
  }
  
  nav .menu-icon .nav-icon.open:after {
    transform: rotate(45deg);
    top: 0;
  }
  

  nav .menu-btn {
    display: none;
  }

  nav .menu-btn:checked~.menu {
    display: block;
  }
  .dateBoxContainer {
    flex-direction: column;
  }

  #reservation {
    padding-top: 10rem;
  }

  .pricesBoxes {
    flex-direction: column;
    gap:0rem;
  }
}
