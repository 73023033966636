.header {
    font-weight: 900;
  }
  
  .textCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
  }
  
  .textCard:nth-child(even) .textCardText {
    order: 2;
  }
  
  .textCardText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .imageBox {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .imageBox img {
    border-radius: 10px;
    object-fit: cover;
  }
  
  #howtouse {
    margin: auto;
    max-width: 90rem;
    padding: 0 3rem;
  }

  .imgSize {
    width: 700px;
    height: 350px;
  }
  
  @media (max-width: 768px) {
    .textCard {
      flex-direction: column;
      align-items: center;
    }
  
    .textCardText, .imageBox {
      width: 100%;
    }
  
    .imageBox {
      margin-top: 1rem;
      height: 20rem;
    }
  
    .imageBox img {
      border-radius: 10px;
    }
  
    #howtouse {
      padding: 0 1rem;
    }
  }
  