/* Modal container - full screen */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    /* Background with opacity */
}

/* Modal content */
.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    /* Maximum width for larger screens */
    border-radius: 10px;
    color: black;
}

/* Close button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        padding: 15px;
        margin: 20% auto;
        /* Adjusted margin for smaller screens */
    }

    .close {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        padding: 10px;
        margin: 25% auto;
        /* Further adjusted margin for small screens */
    }

    .close {
        font-size: 22px;
    }
}