#reservationForm {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

#formContainer {
  width: 100%;
  max-width: 400px;
  /* Set a max width to avoid stretching on large screens */
  padding: 0 20px;
  /* Add some padding for mobile devices */
  text-align: left;
}

.textInput {
  width: 100%;
  /* Ensure the text inputs take up the full width of the container */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.thirdPriceBox {
  height: max-content;
}

#orderForm {
  display: flex;
  flex-direction: column;
}

.agreeWithRulesLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  justify-content: flex-start;
  /* Align items to the left */
}

#agreeWithRules,
#invoice {
  margin-right: 5px;
  cursor: pointer;
}

.formButton {
  align-self: center;
  width: 100%;
  /* Make buttons full width on mobile */
  max-width: 8rem;
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #0056b3;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.linkButton {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font: inherit;
}

.between {
  justify-content: space-between;
}

.linkButton:hover {
  color: darkblue;
  /* Darker blue on hover */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  #formContainer {
    width: 100%;
    padding: 0 15px;
  }

  .textInput {
    padding: 8px;
    /* Adjust padding for smaller inputs on mobile */
  }

  .thirdPriceBox {
    margin: auto;
  }
}