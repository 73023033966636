/* Styling for the payment methods modal */
.payment-methods-modal .p-dialog-header {
  background-color: #0056b3;
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
}

.payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.country-dropdown {
  width: 100%;
}

.payment-methods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.payment-method-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 1rem;
  width: 120px;
  height: 120px; /* Ensure all cards have a consistent height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.payment-method-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.payment-method-logo {
  width: 60px;
  height: auto;
  margin-bottom: 0.5rem; /* Adds spacing between logo and name */
}

.payment-method-name {
  font-size: 0.9rem;
  color: #333;
  text-align: center;
}

.no-methods {
  font-size: 1rem;
  color: #999;
  text-align: center;
  width: 100%;
  padding: 2rem 0;
}
