.payment-return-container {
    background-image: url("../../images/bgForReservationForm.jpg");
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
}

.success-message, .error-message, .loading {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: bold;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    max-width: 600px;
}

.loading {
    font-size: 1.5em;
}

.success-message div, .error-message div {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .success-message, .error-message, .loading {
        width: 80%;
        padding: 15px;
        font-size: 0.9em;
    }

    .loading {
        font-size: 1.2em;
    }

    .success-message div, .error-message div {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .success-message, .error-message, .loading {
        width: 100%;
        padding: 10px;
        font-size: 0.8em;
    }

    .loading {
        font-size: 1em;
    }

    .success-message div, .error-message div {
        margin-bottom: 8px;
    }
}
