#reservationPage {
    background-image: url("../../images/bgForReservationForm.jpg");
    width: 100%;
    height: 100vh; /* Ensure full viewport height */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex; /* Flexbox to center the container */
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Prevent content from overflowing */
}

#reservationContainer {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: bold;
    width: 40%;
    max-width: 90vw; /* Make sure it fits the screen on mobile */
    max-height: 90vh; /* Ensure it doesn't overflow vertically */
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    overflow-y: auto; /* Add scroll if content overflows */
}
#datesBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem 5%;
}

button {
    border-radius: 20px;
    padding: 10px 20px;
}

@media (max-width: 768px) {
    #reservationContainer {
        width: 80%;
        max-width: 90vw;
        padding: 10px; /* Adjust padding for mobile */
    }
}
