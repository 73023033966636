#howToFind {
  text-align: center;
}

.navigationsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5rem 0;
}

.navigationsContainer div {
  max-width: 30rem;
}

.address {
  margin-top: 1rem;
}

.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navIcon {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  color: black
}

.google-map {
  width: 100%;
  height: 300px;
  border: none;
}

.directions {
  margin-top: 20px;
}

button {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.explanation {
  font-size: 0.8rem;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
}

.navigation div {
  width: max-content;
}


.navigationIconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
}

.navigation div {
  display: none;
}


.directionsText {
  padding: 1rem;
}

@media screen and (min-width: 979px) {
  .navigationsContainer {
    justify-content: space-around;
    padding-left: 2rem;
    gap: 2rem;
  }

  .navigationIconsContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .navigation div {
    display: block;
  }
}